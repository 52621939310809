module.exports = {
	"action_log": {
		"create": "Поля при создании",
		"update": "Измененные поля",
		"payment_level_update": "Измененные поля",
		"payment_level_create": "Измененные поля",
		"payment_level_destroy": "Измененные поля",
		"delete": "Поля при удалении",
		"reorder": "Измененная сортировка",
		"field": "Поле",
		"value": "Значение",
		"changes": "Изменения",
		"new_order": "Новая сортировка",
		"reason": "Причина",
		"log_type": {
			"levels": "Уровни",
			"orders": "Заявки",
			"projects": "Проекты",
			"session": "Сессии",
			"system_settings": "Системные настройки",
			"systems": "Платежные системы",
			"users": "Пользователи",
			"order_comments": "Комментарии к заявке",
			"banks": "Банки",
			"processors": "Процессинги",
			"customers": "Игроки",
			"account_balances": "Балансы",
			"alerts": "Алерты",
			"order_failure_codes": "Коды ошибок заявок",
			"customer_free_limits": "Лимиты фридепов",
			"processor_accounts": "Аккаунты процессингов",
			"saved_purses": "Сохраненные кошельки",
			"rules": "Правила",
			"unknown": "Не определен",
			"custom_rates": "Кастомные курсы",
			"monitor_volume": "Мониторинг объемов"
		},
		"action": {
			"create": "Создание",
			"duplicate": "Дублирование",
			"update": "Обновление",
			"delete": "Удаление",
			"reorder": "Пересортировка",
			"update_status": "Обновление статуса",
			"login": "Логин",
			"change_password": "Изменение пароля",
			"payment_level_update": "Обновление уровня оплаты",
			"payment_level_create": "Создание уровня оплаты",
			"payment_level_destroy": "Удаление уровня оплаты",
			"payout": "Оплатить сейчас",
			"move_to_manual_queue": "Перенос в ручную очередь",
			"move_to_pending": "Перенос в статус Новая",
			"payout_at_time": "Оплатить в заданное время",
			"reject": "Отклонить",
			"autoreject": "Автоотклонение",
			"repay": "Перевыплата",
			"refund": "Запрос рефанда",
			"declare_refund": "Фиксация сделанного рефанда",
			"redeposit": "Повторный депозит",
			"move_to_auto_queue": "Перенос в автоматическую очередь",
			"ban_card": "Заблокировать карту",
			"unban_card": "Разблокировать карту",
			"cancel": "Отменить в процессинге"
		},
		"table": {
			"user": "Пользователь",
			"type": "Тип",
			"action": "Действие",
			"object": "Объект",
			"description": "Описание",
			"date": "Дата",
			"additional_info": "Доп. инфо"
		}
	}
};