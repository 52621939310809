import T from "i18n-react"

const DEFAULT_LANGUAGE = "ru"

export const setDictionary = locale => new Promise(resolve => {
  const exportedModule = loadFile(locale)
  const dictionary = exportedModule && exportedModule.default
  T.setTexts(dictionary)
  resolve()
})

const loadFile = locale => {
  let translations
  try {
    translations = require(`ROOT/i18n/${locale}`)
  } catch (e) {
    translations = require(`ROOT/i18n/${DEFAULT_LANGUAGE}`)
  }

  return translations
}

const t = (key, { defaultKey, params = {} } = {}) => {
  const opts = { ...params }
  if (defaultKey) opts.notFound = () => t(defaultKey)
  return T.translate(key, opts)
}

export default t
